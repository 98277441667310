<template>
    <div id="quote-index-page">
        <AdvanceFilter @search="searchFilter" @reset="filterData = null;getDataFromApi('refresh')" :loading="loading"
        @remove="searchFilter($event, 'remove')"/>
        <v-card>
            <v-row class="ma-0 pa-3 pb-5" align="center" justify="end">
                <v-col cols="7" class="d-flex d-xs-none d-sm-none pa-0">
                </v-col>
                <v-col cols="12" class="col-md-5 col-lg-5 col-xl-5 pa-3">
                    <div class="w-full">
                        <v-text-field v-model.trim="search" append-icon="mdi-magnify" class="pt-0"
                        label="" placeholder="Search Quote" :disabled="loading" hide-details
                        @input="searchDatatable">
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="tableItems"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="loading"
                class="elevation-1"
            >
                <template v-slot:[`item.date`]="{item, header}">
                {{ item[header.field] ? $helpers.getMomentDatas(header.format, item[header.field]) : ''}}
                </template>
                <template v-slot:[`item.currency`]="{item, header}">
                $ {{ item.totals[header.field] ? (item.totals[header.field]).toFixed(2) : 0}}
                </template>
                <template v-slot:[`item.action`]="{item}">
                    <v-icon size="20" v-if="item.pdf" class="mr-2 mb-1 cursor-pointer" color="error darken-2" @click="downloadPDF(item.pdf)">
                    mdi-file-pdf-box
                    </v-icon>
                    <v-icon size="20" class="mr-2 mb-1 cursor-pointer" color="info" @click="$router.push(`quotes/${item.id}/invoice`)">
                    mdi-file-eye
                    </v-icon>
                    <v-icon size="20" class="mr-2 mb-1 cursor-pointer" color="primary" @click="$router.push(`quotes/${item.scan_id}`)"
                    :id="`quotes/${item.scan_id}`">
                    mdi-pencil
                    </v-icon>
                    <v-icon size="20" class="mr-2 mb-1 cursor-pointer" color="danger" @click="notify=item.id">
                    mdi-trash-can
                    </v-icon>
                </template>
            </v-data-table>
            <Notify v-if="notify" @close="notify=null" :loading="deleteLoading" @saved="deleteQuoteItem()"
                title="are you sure" success=", delete it!"/>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

import { Notify } from "@/components/common";

export default {
  components: { Notify , AdvanceFilter: () => import("./components/AdvanceFilter")},
  data() {
    return {
      totalItems: 0,
      tableItems: [],
      search: '',
      filterData: null,
      loading: true,
      options: {},
      notify: null,
      deleteLoading: false,
      headers: [
          { text: "Date/Time", value: "date", field:'created_at', format: 'DD-MM-YYYY hh:mm A', sortable: false },
          { text: 'Registration', value: 'licensePlate'},
          {text: 'PDR', value: 'currency', field:'pdr'},
          {text: 'Conventional', value: 'currency', field:'conventional'},
          {text: 'Replace', value: 'currency', field:'replacement'},
          {text: "Push to Paint", value: 'currency', field:'p2p'},
          {text: "Product", value: 'currency', field:'product'},
          {text: "GST", value: 'currency', field:'tax_percent'},
          {text: 'Grand Total', value: 'currency', field: 'grant_total'},
          {text: 'Action', value: 'action', sortable: false}
      ],
      pageLink: `${window.VUE_APP_MS_URL}quote/quotes?type=arch&page=1`,
      prevOptions: null,
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  created() {
    this.searchDatatable = _.debounce(this.searchDatatable, 500);
  },
  methods: {
    ...mapActions(['getModel', 'deleteQuote']),

    searchDatatable() { this.getDataFromApi('search') },
    getDataFromApi (type) {
      this.loading = true;
      this.getDTDetails(type).then(data => {
        this.tableItems = data.items;
        this.totalItems = data.total;
        this.loading = false;
      }).catch(err => {
        this.tableItems = [];
        this.totalItems = 0;
        this.loading = false;
      });
    },
    getDTDetails (type) {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options,
          endPoint = new URL(this.pageLink);
          endPoint.searchParams.set("per_page", itemsPerPage);
          endPoint.searchParams.set("page", page);
          if(type) endPoint.searchParams.set("page", 1);
          if(this.filterData) {
            Object.keys(this.filterData).map(key => this.filterData[key] && endPoint.searchParams.set(key, this.filterData[key]));
          }
          if(this.search !== '') {
            endPoint.searchParams.set("q", this.search);
          }
        this.getAPIdata(endPoint, this.options, type).then(resp => {
          let { items, total } = resp;
          if (sortBy.length === 1 && sortDesc.length === 1) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }
          this.prevOptions = this.options;
          resolve({
            items,
            total,
          })

        }).catch(err => reject(err));
      });
    },
    getAPIdata(endPoint, options, type) {
      const { page, itemsPerPage } = options;
      return new Promise((resolve, reject) => {
        if (!type && this.prevOptions && this.prevOptions.page == page && this.prevOptions.itemsPerPage == itemsPerPage) {
            resolve({items: this.tableItems, total: this.totalItems});
            return true;
        }
        this.getModel(endPoint.toString()).then(resp => {
          resolve(resp);
        }).catch(err => reject(err));
      });
    },
    searchFilter(data, type) {
      if (type) {
        if (this.filterData && this.filterData[data]) this.filterData[data] = null;
        return;
      }
      this.filterData = data;
      this.getDataFromApi('filter')
    },
    async downloadPDF(file) {
      const response = await this.$http.get(file, {
          responseType: "arraybuffer",
      });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "quote.pdf";
      link.click();
  },
    deleteQuoteItem() {
      this.deleteLoading = true;   
      this.deleteQuote(this.notify).then(() => {
        this.notify = null;
        this.deleteLoading = false;
        this.getDataFromApi('delete');
      }).catch(err => this.deleteLoading = false);
    }
  },
};
</script>
